import { setActive } from "./functions";
import gsap from '../../../config/node_modules/gsap';

const formSubmit = (thisContainer) => {
    var forms =  thisContainer.querySelectorAll(".contact-form form");
	if (typeof(forms) === 'undefined' || forms === null) return;

    forms.forEach((form) => {
        var fileInputs = form.querySelectorAll('.form-field__file');
        var avgCheckbox = form.querySelector('.form-field__avg');

        if(fileInputs !== null) {
            Array.prototype.forEach.call( fileInputs, function( input ) {
                var label = input.nextElementSibling,
                    uploadList = label.nextElementSibling,
                    fileIcon = uploadList.nextElementSibling,
                    crossIcon = uploadList.querySelector('.cross-icon');

                input.addEventListener('change', function(e) {
                    var fileName = '';
                    if( this.files && this.files.length > 1 ) {
                        fileName = ( this.getAttribute( 'data_multiple_caption' ) || '' ).replace( '-count-', this.files.length );
                    } else {
                        fileName = e.target.value.split( '\\' ).pop();
                    }
                    if( fileName ) {
                        gsap.to(uploadList, {
                            autoAlpha: 1,
                        })
                        uploadList.querySelector( 'span' ).innerHTML = fileName;
                        gsap.to(fileIcon, {
                            autoAlpha: 0,
                        })
                        crossIcon.classList.add('show-icon');
                    } else {
                        gsap.to(uploadList, {
                            autoAlpha: 0,
                            onComplete: () => {
                                crossIcon.classList.remove('show-icon');
                                setActive(input, false)
                                gsap.to(fileIcon, {
                                    autoAlpha: 1,
                                })
                            }
                        })
                    }
                });
                crossIcon.addEventListener('click', function(e) {
                    e.preventDefault();
                    if (e.target.classList.contains('cross-icon')) {
                        gsap.to(uploadList, {
                            autoAlpha: 0,
                            onComplete: () => {
                                input.value = null;
                                // uploadList.querySelector( 'span' ).innerHTML = labelVal;
                                crossIcon.classList.remove('show-icon');
                                setActive(input, false)
                                gsap.to(fileIcon, {
                                    autoAlpha: 1,
                                })
                            }
                        })
                    }
                });
            });
        }

        form.addEventListener('submit', function(e) {
            e.preventDefault();

            if(validateForm(form, avgCheckbox) === false) return; //return false
            
            executeRecaptcha(form, function(){
                form.submit();
            })
        });
    });
}

function validateForm(thisForm, thisAvg) {
    var y, i, l, g, s, f,
        valid = false,
        validRadios = false,
        validInputs = false,
        validSelects = false,
        validCheckboxes = false,
        validFiles = false,
        validAvg = false,
        validGroupCount = 0,
        validInputCount = 0,
        validSelectCount = 0,
        validFilesCount = 0;

    l = thisForm.querySelectorAll("input[type='radio']");
    y = thisForm.querySelectorAll(".form-field__input");
    g = thisForm.querySelectorAll("input[type='checkbox']");
    s = thisForm.querySelectorAll(".form-field__select");
    f = thisForm.querySelectorAll(".form-field__file");

    if (l.length > 0) {
        var radioGroups = [];
        for (i = 0; i < l.length; i++) {
            var name = l[i].getAttribute("name");
            if(radioGroups.indexOf(name) === -1) {
                radioGroups.push(name);
            }
        }
        for (i = 0; i < radioGroups.length; i++) {
            var el = thisForm.querySelectorAll('input[name="'+ radioGroups[i] +'"]');
            el[m].closest('.form-field').classList.remove("valid");
            el[0].closest('.form-field').classList.add("invalid");

            for (var m=0; m<el.length; m++) {
                if (el[m].checked && el[m].name == radioGroups[i]) {
                    validGroupCount++
                    el[m].closest('.form-field').classList.remove("invalid");
                    el[m].closest('.form-field').classList.add("valid");
                    // formFieldsArray.push(el[m]);
                }
            }
        }
        if (radioGroups.length === validGroupCount || radioGroups.length === 0) {
            validRadios = true;
        } else {
            validRadios = false;
        }
    } else {
        validRadios = true;
    }
    
    if (g.length > 0) {
        for (i = 0; i < g.length; i++) {
            if (g[i].checked !== true && g[i].closest('.form-field').getAttribute('data-required') === 'true') {
                g[i].classList.remove("valid");
                g[i].classList.add("invalid");
            } else {
                [].forEach.call(g, function(el) {
                    el.classList.remove("invalid");
                    el.classList.add("valid");
                });
                validCheckboxes = true;
            }
        }
    }
    
    if (y.length > 0) {
        for (i = 0; i < y.length; i++) {
            if (y[i].value == "" && y[i].closest('.form-field').getAttribute('data-required') === 'true') {
                y[i].classList.remove("valid");
                y[i].classList.add("invalid");
                y[i].classList.remove("invalid-input");
            } else {
                if(y[i].type === "email" && y[i].closest('.form-field').getAttribute('data-required') === 'true' || y[i].type === "tel"  && y[i].closest('.form-field').getAttribute('data-required') === 'true') {
                    let emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    let phoneRe = /^\d{10,}$/;

                    if (y[i].type === "email") {
                        if (emailRe.test(y[i].value.toLowerCase()) != true) {
                            y[i].classList.remove("valid");
                            y[i].classList.add("invalid");
                        } else {
                            y[i].classList.remove("invalid");
                            y[i].classList.add("valid");
                            validInputCount++
                            // formFieldsArray.push(y[i]);
                        }
                    }
                    if (y[i].type === "tel") {
                        if (phoneRe.test(y[i].value.toLowerCase()) != true) {
                            y[i].classList.remove("valid");
                            y[i].classList.add("invalid");
                        } else {
                            y[i].classList.remove("invalid");
                            y[i].classList.add("valid");
                            validInputCount++
                            // formFieldsArray.push(y[i]);
                        }
                    }

                } else {
                    y[i].classList.remove("invalid");
                    y[i].classList.add("valid");
                    validInputCount++
                    // formFieldsArray.push(y[i]);
                }
                
            }
            
        }
    }
    if (y.length === validInputCount || y.length === 0) {
        validInputs = true;
    } else {
        validInputs = false;
    }

    if (s.length > 0) {
        for (i = 0; i < s.length; i++) {

            if (s[i].value == "disabled" && s[i].closest('.form-field').getAttribute('data-required') === 'true') {
                s[i].classList.remove("valid");
                s[i].classList.add("invalid");
            } else {
                s[i].classList.remove("invalid");
                s[i].classList.add("valid");
                validSelectCount++
                // formFieldsArray.push(s[i]);
            }
            
        }
    }
    if (s.length === validSelectCount || s.length === 0) {
        validSelects = true;
    } else {
        validSelects = false;
    }

    if (f.length > 0) {
        for (i = 0; i < f.length; i++) {
            if (f[i].files.length == 0 && f[i].closest('.form-field').getAttribute('data-required') === 'true') {
                f[i].classList.remove("valid");
                f[i].closest('.form-field').classList.add("invalid");
            } else {
                f[i].closest('.form-field').classList.remove("invalid");
                f[i].closest('.form-field').classList.add("valid");
                validFilesCount++
            }
        }
    }
    if (f.length === validFilesCount || f.length === 0) {
        validFiles = true;
    } else {
        validFiles = false;
    }

    if (thisAvg.checked !== true && thisAvg.closest('.form-field').getAttribute('data-required') === 'true') {
        thisAvg.closest('.form-field').classList.remove("valid");
        thisAvg.closest('.form-field').classList.add("invalid");
    } else {
        thisAvg.closest('.form-field').classList.remove("invalid");
        thisAvg.closest('.form-field').classList.add("valid");
        validAvg = true;
    }
    
    if(validRadios === true && validInputs === true && validSelects === true && validFiles === true && validAvg === true) {
        valid = true;
    }

    return valid;
}

export {
    formSubmit
}
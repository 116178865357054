import gsap from '../../../config/node_modules/gsap';
import {scrollCont} from './scrollContainer';

async function anchorScroll() {

    var anchorLinks = document.querySelectorAll('.anchor-scroll');
    var mediaQuery = window.matchMedia("(min-width: 1024px)");
    if (typeof(anchorLinks) === 'undefined' || anchorLinks === null) return;

    gsap.utils.toArray(anchorLinks).forEach(function (button, i) {
        button.addEventListener("click", (e) => {
            var id = e.target.getAttribute("href");
            var offset = e.target.getAttribute("data-offset");
            var scrollOffset;

            if (offset !== null) {
                scrollOffset = offset;
            } else {
                scrollOffset = "top top";
            }

            if(scrollCont) {
                scrollCont.scrollTo(id, true, scrollOffset);
            } else {
                const topPos = document.querySelector(id).getBoundingClientRect().top + window.pageYOffset;

                window.scrollTo({
                    top: topPos,
                    left: 100,
                    behavior: "smooth",
                });
            }
            e.preventDefault();
        });
    });

}

export {
    anchorScroll
}
import {scrollCont} from './scrollContainer';
import {debounce} from './functions';
import { adScript } from './general';
import { gsap } from '../../../config/node_modules/gsap';
import { observeImages } from './lazyload';

async function filter(thisContainer) {
	var selectedFilters = [];
    var filterWrapper = document.querySelector('.posts-filter');
	var taxs = document.querySelectorAll(".filter-button");
	var taxonomyFiltersSelect = document.querySelectorAll(".tax-filter-select__input");
	var textFilter = document.querySelectorAll(".text-filter__input");
	var dateFilters = document.querySelectorAll(".tax-filter-date__input");
	var postType;
	var theEnd = false;
	var appended = false;
    var loadMoreButton = document.querySelector('.result__container .load-more');
    // var pagination = document.querySelector('.pagination');
	var clickedLoadMore = false;
	var hasFeatured = false;
	var container = document.querySelector("#result__wrapper");
	var selectedFilter = document.querySelector('#filters');
	var dateFilter = document.querySelector('.date-filter');
	var categoryFilter = document.querySelector('.category-filter');
	var sourceFilter = document.querySelector('.source-filter');
	var audienceFilter = document.querySelector('.audience-filter');
	var pagination = thisContainer.querySelector('.result__container .paginate');
    var paginationItems = thisContainer.querySelectorAll('.result__container .paginate .paginate__list__item:not(.no-hover)');
	var i = 0;
	var x = 0;
	var nonBlankKeys;

	// console.log(pagination);
    selectedFilters['page'] = 1;

	if(typeof(container) != 'undefined' && container != null) {
		if(typeof(paginationItems) != 'undefined' && paginationItems != null) {
			addPaginationListeners(paginationItems);
		}

		if (container.innerHTML.includes('featured-item')) {
			hasFeatured = true;
			selectedFilters.featured = true;
		}

		if(typeof(loadMoreButton) != 'undefined' && loadMoreButton != null) {
			showHideButton(theEnd);
		}

		function makeRequest(data, type) {
			var text = [];
			var i;
			for (i in data) {
				if (data.hasOwnProperty(i)) {
					text.push(i + "=" + encodeURIComponent(data[i]));
				}
			}
			var textState = [];
			var i;
			for (i in data) {
				if (data.hasOwnProperty(i)) {
						textState.push(i + "=" + encodeURIComponent(data[i]));
				}
			}
			
			text = text.join("&");
			textState = textState.join("&");
			var url = "/filters?" + text;
			var pushStateUrl = "?" + textState;

			window.history.pushState(null, null, pushStateUrl);

			var xhr = window.XMLHttpRequest ? new XMLHttpRequest : window.ActiveXObject ? new ActiveXObject("Microsoft.XMLHTTP") : (alert("Browser does not support XMLHTTP."), false);
			xhr.onreadystatechange = text;
			xhr.open("GET", url, true);
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.send(text);
			xhr.onload = function() {
				setTimeout(function() {
					container.classList.add("fade-out");
					if  (xhr.response.includes('pagination-info')) {
						reInitPagination(xhr.response);
						// alert('test');
					}
					
					var resp = xhr.response.split('✂✂✂✂✂✂✂✂✂✂✂✂');

					if (type == 'append') {
						container.innerHTML = container.innerHTML + resp[0];
					} else {
						container.innerHTML = resp[0];
					}

					selectedFilter.innerHTML = resp[1];
				
					if (Object.keys(selectedFilters).length > 1) {
						gsap.to(document.querySelector('.delete-filter'), {
						   autoAlpha: 1,
						   pointerEvents: 'all',
						   duration: .3,
					   });
					}

					if (!xhr.response.includes('+==THEEND==+')) {
						setTimeout(function() {
							appended = false;
						}, 1000);
					} else {
						theEnd = true;
					}
					if(typeof(loadMoreButton) != 'undefined' && loadMoreButton != null) {
						showHideButton(theEnd);
					}

					setDates(dateFilter, resp[2]);
					setActiveButtons(categoryFilter, resp[3], selectedFilters);
					setActiveButtons(sourceFilter, resp[4], selectedFilters);
					setActiveButtons(audienceFilter, resp[5], selectedFilters);
					
					// setPagination(pagination, resp[6]);
					
					

					setTimeout(function() {
						container.classList.remove('fade-out');
						if(typeof(loadMoreButton) != 'undefined' && loadMoreButton != null) {
							loadMoreButton.classList.remove('is-loading');
						}
						observeImages();
						removeAttr();
						
						// scrollCont.update();
					}, 50);
				}, 100);
			};
			xhr.onerror = function() {
				alert("error");
			};
		}
		
		
		function reInitPagination(response) {
			var parser = new DOMParser();
			var doc = parser.parseFromString(response, "text/html");
			// console.log(doc);
			// console.log({response});
			var paginationInfo = doc.querySelector(".pagination-info").innerHTML;
			var prevAttr = doc.querySelector(".pagination-info").getAttribute("data-prev");
			var nextAttr = doc.querySelector(".pagination-info").getAttribute("data-next");
			var activeAttr = doc.querySelector(".pagination-info").getAttribute("data-active");
			
			// console.log({prevAttr}, {nextAttr}, {activeAttr})
			var activePaginationItem = thisContainer.querySelector(".paginate .paginate__list__item--active");
			activePaginationItem.classList.remove("paginate__list__item--active");
			
			// console.log(activePaginationItem);
			setTimeout(() => {
				// console.log(paginationInfo);
				pagination.querySelector(".paginate__list").innerHTML = paginationInfo;

				var paginationItems = pagination.querySelectorAll(".paginate__list__item:not(.no-hover)");
				var paginationPrev = pagination.querySelector(".paginate__btn--prev");
				var paginationNext = pagination.querySelector(".paginate__btn--next");

				if (prevAttr === "true" || prevAttr === true) {
					paginationPrev.classList.remove("paginate__btn--disabled");
				} else {
					paginationPrev.classList.add("paginate__btn--disabled");
				}

				if (nextAttr === "true" || nextAttr === true) {
					paginationNext.classList.remove("paginate__btn--disabled");
				} else {
					paginationNext.classList.add("paginate__btn--disabled");
				}

				if (activeAttr) {
					setTimeout(() => {
						pagination.querySelector('.paginate__list__item[data-page="' + activeAttr + '"]').classList.add("paginate__list__item--active");
					}, 10);
				}

				addPaginationListeners(paginationItems);
			}, 200);
		}

		function addPaginationListeners(array) {
			array.forEach(element => {
				element.addEventListener('click', debounce(function (event) {
					var pageAttr = parseInt(element.getAttribute("data-page"));
					selectedFilters.data_object = filterWrapper.getAttribute("data-object");
					selectedFilters['page'] = pageAttr;
					
					// console.log(selectedFilters['page']);

					window.scrollTo({
						top: document.querySelector('.title-header').offsetHeight - 170,
						left: 0,
						behavior: "smooth",
					});

					makeRequest(selectedFilters);
				}, 200));
			});
		}

		function prevNextPage(type) {
			if(selectedFilters['page'] === undefined) {
				selectedFilters['page'] = 1
			}

			if (type == "next") {
				selectedFilters['page'] = selectedFilters['page'] + 1;
			} else {
				selectedFilters['page'] = selectedFilters['page'] - 1;
			}


			window.scrollTo({
				top: document.querySelector('.title-header').offsetHeight - 170,
				left: 0,
				behavior: "smooth",
			});

			makeRequest(selectedFilters);
		}

		if(typeof(pagination) != 'undefined' && pagination != null) {
			var prevPageBtn = pagination.querySelector(".paginate__btn--prev");
			var nextPageBtn = pagination.querySelector(".paginate__btn--next");
			
			if(prevPageBtn !== null) {
				prevPageBtn.addEventListener('click', debounce(function (clickEvent) {
					clickEvent.preventDefault();

					selectedFilters.data_object = filterWrapper.getAttribute("data-object");
					
					prevNextPage("prev");
				}, 200));
			}
			if(nextPageBtn !== null) {
				nextPageBtn.addEventListener('click', debounce(function (clickEvent) {
					clickEvent.preventDefault();

					selectedFilters.data_object = filterWrapper.getAttribute("data-object");
					
					prevNextPage("next");
				}, 200));
			}
		}


		document.querySelector('.delete-filter').addEventListener('click', debounce(function (e) {
			Object.keys(selectedFilters).forEach((item) => {
				selectedFilters[item] = '';
			});

			selectedFilters.theme = 'all';
			selectedFilters.clicked = 'theme';
			selectedFilters.data_object = 'knowledge_bases';

			document.querySelectorAll('[data-object="knowledge_bases"]').forEach((filterItem) => {
				if (filterItem.getAttribute('value') === 'all') {
					filterItem.classList.add('active');
				} else {
					filterItem.classList.remove('active');
				}
			})
			
			makeRequest(selectedFilters, 'filter');
		}), 1000);	


		function removeAttr(attrs) {
			document.querySelectorAll('[data-group="filters"] li').forEach((item) => {
				// console.log(item.getAttribute('data-key'));
				item.addEventListener('click', debounce(function (e) {
					if (item.getAttribute('data-key') === 'theme') {
						selectedFilters.theme = 'all';
						document.querySelectorAll('[data-parent="theme"]').forEach((filterItem) => {
							if (filterItem.getAttribute('value') === 'all') {
								filterItem.classList.add('active');
							} else {
								filterItem.classList.remove('active');
							}
						});
						
					} else if (item.getAttribute('data-key') === 'category') {
						selectedFilters.category = '';
						document.querySelectorAll('[data-parent="category"]').forEach((filterItem) => {
							filterItem.classList.remove('active');
						})
					} else if (item.getAttribute('data-key') === 'source') {
						selectedFilters.source = '';
						document.querySelectorAll('[data-parent="source"]').forEach((filterItem) => {
							filterItem.classList.remove('active');
						})
					} else if (item.getAttribute('data-key') === 'date_from') {
						selectedFilters.date_from = '';
						selectedFilters.date_to = '';
						document.querySelectorAll('input.date-filter-from').forEach((filterItem) => {
							filterItem.value = filterItem.min;
						})
						document.querySelectorAll('input.date-filter-to').forEach((filterItem) => {
							filterItem.value = filterItem.max;
						})
					} else if (item.getAttribute('data-key') === 'audience') {
						selectedFilters.audience = '';
						document.querySelectorAll('[data-parent="audience"]').forEach((filterItem) => {
							filterItem.classList.remove('active');
						})
					}
					makeRequest(selectedFilters, 'filter');
				}), 300);
			})
		}
		
		function loadNextPage() {
			// console.log(appended);
			// console.log(theEnd);
			if (!appended && !theEnd) {
				appended = true;
				selectedFilters['page'] = selectedFilters['page'] + 1;

				makeRequest(selectedFilters, 'append');
			}
		}
		if(typeof(loadMoreButton) != 'undefined' && loadMoreButton != null) {
			loadMoreButton.addEventListener('click', function (clickEvent) {
				clickEvent.preventDefault();
				loadMoreButton.classList.add('is-loading');
                selectedFilters.data_object = filterWrapper.getAttribute("data-object");

				loadNextPage();
			});
		}
		// if(typeof(pagination) != 'undefined' && pagination != null) {
			
		// 	pagination.querySelectorAll('li').forEach((paginateItem) => {
		// 		paginateItem.addEventListener('click', function (clickEvent) {
		// 			clickEvent.preventDefault();
		// 			selectedFilters.data_object = filterWrapper.getAttribute("data-object");
		// 			selectedFilters['page'] = paginateItem.getAttribute('page-object');
		// 			window.scrollTo({
		// 				top: document.querySelector('.title-header').offsetHeight - 170,
		// 				left: 0,
		// 				behavior: "smooth",
		// 			});

		// 			pagination.querySelectorAll('li').forEach((i) => {
		// 				i.classList.remove('active');
		// 			})
		// 			paginateItem.classList.add('active');
		// 			makeRequest(selectedFilters, 'filter');
		// 		});
		// 	});
		// }
		
		function showHideButton(isEnd) {
			var autoAlphaIs = 1;
			if(isEnd === true) {
				autoAlphaIs = 0;
			} else {
				autoAlphaIs = 1;
			}
			gsap.to(loadMoreButton, {
				autoAlpha: autoAlphaIs,
			})
		}
		
		
		if (taxs !== null) {
			filterButtons(taxs);
		}
		
		function filterButtons(itms) {
			for (; i < itms.length; i++) {
				// console.log(itms[i]);
				postType = itms[i].getAttribute("data-object");
				itms.data_object = postType;
				itms[i].addEventListener("click", debounce(function (e) {
					
					selectedFilters['page'] = 1;
					appended = false;
					theEnd = false;

					var parentTax = this.getAttribute("data-parent"),
						parentObject = this.getAttribute('data-object'),
						checkId = this.getAttribute("value"),
						parentTaxArray = selectedFilters[parentTax] || [],
						parentFilter = document.querySelector('[data-group="'+ parentTax.split('_')[0] +'"');
				
					selectedFilters.data_object = parentObject;
					if (selectedFilters.theme === undefined) {
						selectedFilters.theme = 'all';
					}

					if (parentTax == 'theme' || parentTax == 'date_to' || parentTax == 'date_from') {
						[].forEach.call(parentFilter.querySelectorAll('[data-parent]'), function(el) {
							el.classList.remove("active");
						});
						this.classList.add('active');

						selectedFilters[parentTax] = checkId;
					} else {
						if (!this.classList.contains('active')) {
							parentTaxArray.push(checkId);
							this.classList.add('active');
						} else {
							var index = parentTaxArray.indexOf(checkId);
							if (index !== -1) {
								parentTaxArray.splice(index, 1);
							}
							this.classList.remove('active');
						}
						selectedFilters[parentTax] = parentTaxArray;
					}

					if (parentTax == 'theme') {
						selectedFilters.category = '';
						selectedFilters.s = '';
						selectedFilters.source = '';
						selectedFilters.audience = '';
					}
					selectedFilters.clicked = parentTax;
					makeRequest(selectedFilters, 'filter');
				}, 100));
			}
		}
		

		if (taxonomyFiltersSelect !== null) {
            for (; i < taxonomyFiltersSelect.length; i++) {
                taxonomyFiltersSelect[i].addEventListener('change', debounce(function(event) {
                    selectedFilters['page'] = 1;
                    appended = false;
                    theEnd = false;
					postType = this.getAttribute("data-object");
					selectedFilters.data_object = postType;
					
					if (selectedFilters.theme === undefined) {
						selectedFilters.theme = 'all';
					}
					if(hasFeatured === true) {
						selectedFilters.featured = false;
					}

                    var parentTax = this.getAttribute("data-parent");
                    var sortValue = this.options[this.selectedIndex].value;
                    
                    selectedFilters[parentTax] = sortValue;
                    makeRequest(selectedFilters, 'filter');
                }, 100));
            }
		}

		if (textFilter !== null) {
			textFilter.forEach((item) => {
				item.addEventListener('keyup', debounce(function(event) {
					selectedFilters['page'] = 1;
					var parentTax = this.getAttribute("data-parent");
					var keyUpValue = this.value;
					appended = false;
					theEnd = false;
					postType = this.getAttribute("data-object");
					selectedFilters.data_object = postType;

					if (selectedFilters.theme === undefined) {
						selectedFilters.theme = 'all';
					}	

					if (item.classList.contains('text-filter__input--small')) {
						gsap.to(item, {
							width: 200,
							duration: .3,
						})
						selectedFilters['advanced'] = 'true';
					} else {
						selectedFilters['advanced'] = 'false';
					}

					if(hasFeatured === true) {
						selectedFilters.featured = false;
					}
					selectedFilters[parentTax] = keyUpValue;
					makeRequest(selectedFilters, 'filter');
				}, 300));
				
				setInterval(() => {
					if (item.classList.contains('text-filter__input--small')) {
						if (item.value.length === 0) {
							gsap.to(item, {
								width: 84,
								duration: .3,
							})
						}
					}
				}, 3000);

				if (!item.classList.contains('text-filter__input--small')) {
					item.closest('.form-field').querySelector('button').addEventListener('click', () => {
						// alert('advanced--search')
						if (item.value.length !== 0) {
							selectedFilters['advanced'] = 'true';
						} else {
							selectedFilters['advanced'] = 'false';
						}
						// console.log(selectedFilters['advanced']);
						makeRequest(selectedFilters, 'filter');
					})
				}
			})
		}

		

		if (dateFilters !== null) {
			for (; x < dateFilters.length; x++) {
				dateFilters[x].closest('.date-filter').querySelector('.tax-filter-date-arrow').addEventListener('click', () => {
					selectedFilters.data_object = 'knowledge_bases';
					if (selectedFilters.theme === undefined) {
						selectedFilters.theme = 'all';
					}
					selectedFilters.date_from = dateFilters[0].value;
					selectedFilters.date_to = dateFilters[1].value;
					makeRequest(selectedFilters, 'filter');
				})
				dateFilters[x].addEventListener('change', debounce(function(event) {
					// console.log(dateFilters);
					selectedFilters['page'] = 1;
					var parentTax = this.getAttribute("data-parent");
					var dateValue = this.value;
					appended = false;
					theEnd = false;
					postType = this.getAttribute("data-object");
					selectedFilters.data_object = postType;
					if (selectedFilters.theme === undefined) {
						selectedFilters.theme = 'all';
					}

					if(hasFeatured === true) {
						selectedFilters.featured = false;
					}

					if (parentTax == 'date_to' && selectedFilters.date_from == null) {
						selectedFilters.date_from = dateFilters[0].value;
					} 

					if (parentTax == 'date_from' && selectedFilters.date_to == null) {
						selectedFilters.date_to = dateFilters[1].value;
					}

					// console.log(parentTax)

					selectedFilters[parentTax] = dateValue;
					makeRequest(selectedFilters, 'filter');
				}, 300));
			}
		}

		function setDates(c, q) {
			var setDater = q.split(',').map(function(value) {
				return value.trim();
			});

			// c.querySelector('.date-filter-from input').value = setDater[0];
			// c.querySelector('.date-filter-to input').value = setDater[1];
			
			c.querySelector('.date-filter-from input').setAttribute('value', setDater[0]);
			c.querySelector('.date-filter-to input').setAttribute('value', setDater[1]);

			// c.querySelector('.date-filter-from input, .date-filter-to input').min = setDater[0];
			c.querySelector('.date-filter-from input, .date-filter-to input').setAttribute('min', setDater[0]);

			// c.querySelector('.date-filter-from input, .date-filter-to input').max = setDater[1];
			c.querySelector('.date-filter-from input, .date-filter-to input').setAttribute('max', setDater[1]);
		}
		
		function setPagination(c, q) {
			c.querySelectorAll('li').forEach((item) => {
				if (parseInt(q) >= item.getAttribute('page-object')) {
					item.classList.add('visible');
				} else {
					item.classList.remove('visible');
				}
			})
		}

		
		function setActiveButtons(buttons, query) {
			// console.log(buttons, query);
			if (buttons != null) {
				if (selectedFilters['clicked'] === 'theme') {
					buttons.querySelectorAll('.filter-button').forEach(function(fb) {
						fb.classList.remove('active');
					});
			
					// Controleer of het geselecteerde thema "all" is
					if (selectedFilters['theme'] === 'all') {
						// Toon alle filterknoppen door de "invisible" klasse te verwijderen
						buttons.querySelectorAll('.filter-button').forEach(function(fb) {
							fb.classList.remove('invisible');
						});
					} else {
						// Split de query op komma's en verwijder eventuele witruimte
						var queryArray = query.split(',').map(function(value) {
							return value.trim();
						});
			
						// Verberg alle filterknoppen door de "visible" klasse te verwijderen en de "invisible" klasse toe te voegen
						buttons.querySelectorAll('.filter-button').forEach(function(fb) {
							fb.classList.remove('visible');
							fb.classList.add('invisible');
						});
			
						// Itereer over elk ID in de queryArray
						queryArray.forEach(function(id) {
							// Zoek het knopelement met de bijbehorende waarde (ID)
							var targetButton = buttons.querySelector('[value="' + id + '"]');
							
							// Controleer of het doelknop-element bestaat
							if (targetButton) {
								// Maak het doelknop-element zichtbaar door klassen toe te voegen/verwijderen
								targetButton.classList.add('visible');
								targetButton.classList.remove('invisible');
							}
						});
					}
				}
			}
		}
		

		(function() {
         
			var paramFilters = [];
			// Filter based on parameters in URL
			var param_object = getUrlParameter('data_object');
			var param_categories = getUrlParameter('categories');
			var param_page = getUrlParameter('page');

			if (param_categories) {
				selectedFilters['categories'] = param_categories;
			}
			var param_date_from = getUrlParameter('date_input_from');
			var param_date_to = getUrlParameter('date_input_to');

			if (param_object || param_categories || param_page) {

				paramFilters.data_object = param_object;
				paramFilters.page = param_page;
				// console.log(paramFilters.page);
				if (param_categories !== undefined) {
					var categoriesElement = $('*[value="' + param_categories + '"]');

					paramFilters.categories = param_categories;

					$(taxonmyFilters).removeClass('active');
					$(categoriesElement).addClass('active');

					if(categoriesElement[0].nodeName == "OPTION") {
						$(categoriesElement).prop("selected", "selected");
					}
				}
				
				makeRequest(paramFilters);
			}
        })();

		// Get parameters from URL
		function getUrlParameter(sParam) {
			var sPageURL = window.location.search.substring(1),
				sURLVariables = sPageURL.split('&'),
				sParameterName,
				i;

			for (i = 0; i < sURLVariables.length; i++) {
				sParameterName = sURLVariables[i].split('=');

				if (sParameterName[0] === sParam) {
					return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
				} else {}
			}
		}
	}

	function elementInViewport(el, top, height, offset, scrollY) {

		while (el.offsetParent) {
			el = el.offsetParent;
			top += el.offsetTop;
		}

		if (scrollY === false) {
			return true;
		} else {
			return (
				(scrollY + window.innerHeight) > (height + top - offset)
			);
		}
	}
} export {
	filter
}
import barba from '../../../config/node_modules/@barba/core';
import { gsap } from '../../../config/node_modules/gsap';
import ScrollTrigger from '../../../config/node_modules/gsap/ScrollTrigger';
import ScrollSmoother from '../../../config/node_modules/gsap/ScrollSmoother';
import { observeImages } from './lazyload';
import { formLabels, ifEditing, copyLink } from './general';
import { formSubmit } from './form';
import { filter } from './filter';
// import { imageSlider } from './swiper';
// import { video } from './video';
// import { initPopups } from './components/popup';
import { menu } from './menu';
import { anchorScroll } from './anchorScroll';
// import LightNav from './navigation';
// import { initFullwidthMenu } from './components/fullwidthMenu'
// import { initGmaps } from './dealers';
// import { loadScript } from './functions';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
// ScrollTrigger.normalizeScroll({allowNestedScroll: true});

ScrollTrigger.clearScrollMemory('manual');

ScrollTrigger.config({
    ignoreMobileResize: true
});

let scrollCont;
var minMediaQuery = window.matchMedia("(min-width: 576px)")
// var preloadContainer = document.querySelector('.preloader');

barba.hooks.beforeOnce((data) => {
    // if(preloadContainer !== null) {
    //     preloader(data.next.container);
    // }
});
barba.hooks.once((data) => {
    ifEditing();
});
barba.hooks.before((data) => {
});
barba.hooks.leave((data) => {
});
barba.hooks.afterLeave((data) =>  {
    ScrollTrigger.getAll().forEach(t => {t.kill()});
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    // console.log(window.history)
});
barba.hooks.beforeEnter((data) => {
    document.querySelector('body').classList.remove('lock-scroll');
    // --- Menu ---
    // initFullwidthMenu(data.next.container);

    // --- Forms ---
    formLabels(data.next.container);
    formSubmit(data.next.container);

    // --- Sliders ---
    // imageSlider(data.next.container);

    // --- Video ---
    // video(data.next.container);

    // --- Popup ---
    // initPopups(data.next.container);

    // --- Anchor scroll ---
    anchorScroll(data.next.container);
    
});
barba.hooks.afterEnter((data) => {
    menu(data.next.container);
    
})
barba.hooks.enter((data) => {
    
})
barba.hooks.after((data) => {
    observeImages();
    // scrollCont.update();
    if(scrollCont) {
        scrollCont.refresh();
    }
});
barba.init({
    debug: true, //op "false" zetten voor livegang
    timeout: 5000,
    views: [{
        namespace: 'home',
        beforeLeave(data) {
        },
        afterLeave(data) {
        },
        beforeEnter(data) {
        },
        afterEnter(data) {
            filter(data.next.container);
        },
    }, {
        namespace: 'knowledge_base-index',
        afterEnter(data) {
            filter(data.next.container);
        },
    }, {
        namespace: 'knowledge_base-show',
        afterEnter(data) {
            copyLink(data.next.container);
        },
    }, {
        namespace: '404',
        afterEnter(data) {
        },
    }],
	transitions: [{
            name: 'page',
            sync: false,
            once({ next }) {
                // init scrollcontainer on page load
                smooth(next.container);
            },
            leave({ current }) {
                //const done = this.async();
                // pageTransitionIn(done);
                current.container.classList.add('leave');
                return gsap.to(current.container, {
                    autoAlpha: 0,
                    duration: .4,
                });
            },
            beforeEnter({ next }) {
                next.container.classList.add('come');
                // destroy the previous scrollcontainer
                if(scrollCont) {
                    scrollCont.kill();
                }

                // init scrollcontainer regarding the next page
                smooth(next.container);
            },
            enter({ next }) {
                return gsap.to(next.container, {
                    autoAlpha: 1,
                    duration: .4,
                    onComplete: function () {
                        next.container.classList.remove('come')
                    }
                });
            }
        },
    ]
});

function smooth(container) {
    // const lightNavCheck = new LightNav(container);
    let smoothEffect = .8;

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
        smoothEffect = 0; //0
    }

    if (ScrollTrigger.isTouch !== 1) {
        scrollCont = ScrollSmoother.create({
            wrapper: container.querySelector("#smooth-wrapper"),
            content: container.querySelector("#smooth-content"),
            smooth: smoothEffect,
            effects: false,
            smoothTouch: false,
            ignoreMobileResize: true,
            // normalizeScroll: true,
        });
    }

    // lightNavCheck.on();
    
    // console.log({scrollCont})
    
}

export {
    scrollCont, barba
};

import {scrollCont, barba} from './scrollContainer';
import {debounce, throttle, setActive} from './functions';

async function ifEditing() {
    document.addEventListener('DOMContentLoaded', (event) => {
        if(document.body.classList.contains("config-mode")) {
        
            barba.destroy();
            if(scrollCont) {
                scrollCont.kill();
                // scrollCont.destroy();
            }
            
        }
    })
}
async function formLabels() {
[].forEach.call(
    document.querySelectorAll('.form-field__label, .form-field__input, .form-field__select, .form-field__textarea, .form-field__file'),
    el => {
        el.onblur = () => {
            setActive(el, false);
        };
        el.onfocus = () => {
            setActive(el, true);
        };
    });
}

async function copyLink(container) {
    // Functie om de klik op een element met de klasse 'copy-link' te verwerken
    function handleCopyLinkClick(e) {
        e.preventDefault(); // Voorkomt de standaard actie van de link

        // Haal de 'href' attribuutwaarde op van het geklikte element
        var href = this.getAttribute('href');

        // Roep de functie aan om naar het klembord te kopiëren
        copyToClipboard(href);
    }

    // Functie om tekst naar het klembord te kopiëren
    function copyToClipboard(text) {
        // Maak een tijdelijk input-element aan
        var tempInput = document.createElement('input');

        // Voeg het tijdelijke input-element toe aan de body van het document
        document.body.appendChild(tempInput);

        // Wijs de gewenste tekst toe aan de waarde van het tijdelijke input-element en selecteer deze
        tempInput.value = text;
        tempInput.select();

        // Voer het 'copy' commando uit om de geselecteerde tekst naar het klembord te kopiëren
        document.execCommand('copy');

        // Verwijder het tijdelijke input-element
        document.body.removeChild(tempInput);
        alert('Link gekopieerd naar clipboard')
    }

    // Zoek alle elementen met de klasse 'copy-link'
    var copyLinkElements = container.querySelectorAll('.copy-link');

    // Voeg een klikgebeurtenislistener toe aan elk van deze elementen
    copyLinkElements.forEach(function(element) {
        element.addEventListener('click', handleCopyLinkClick);
    });
}


export {
    ifEditing, formLabels, copyLink
}
import { throttle } from './functions';
import gsap from '../../../config/node_modules/gsap';
import { CustomEase } from '../../../config/node_modules/gsap/CustomEase';
gsap.registerPlugin(CustomEase);

async function menu(currentContainer) {
    var clicks = !0,
    navbarToggler = currentContainer.querySelector(".navbar-toggler");
    const mediaQuery = window.matchMedia('(max-width: 1024px)')

    if (navbarToggler && mediaQuery.matches) {

        function runMenu(e) {
            !0 === e ? menuTL.timeScale(.8).play() : menuTL.timeScale(1.6).reverse()
        }

        navbarToggler.addEventListener("click", throttle(function(e) {
            var t = currentContainer.querySelector(".navigation"),
                o = document.querySelector("body");
            (clicks = !clicks) ? (o.classList.remove("lock-scroll"), runMenu(!1), t.classList.remove("menu-open")) : (o.classList.add("lock-scroll"), runMenu(!0), t.classList.add("menu-open"))
        }, 1000));

        var menuTL = gsap.timeline({paused: true}),
        nav = currentContainer.querySelector(".navigation"),
        menuParent = currentContainer.querySelector("#navbarResponsive"),
        MainMenuItems = currentContainer.querySelectorAll(".navigation .navbar-nav .nav-item"),
        MainMenuItemsInner = currentContainer.querySelectorAll(".navigation .navbar-nav .nav-item .nav-link");
    
        menuTL.set(MainMenuItemsInner, {autoAlpha: 0, y: 100});

        menuTL.to(menuParent, {
            autoAlpha: 1,
        }).to(nav, {
            yPercent: 0,
        }, '<').to('.bar-1', { 
            y: 4,
            rotate: 45,
            duration: .75,
            ease: CustomEase.create("custom", "M0,0,C0.126,0.382,0.082,0.78,0.24,0.928,0.432,1.108,0.818,1.001,1,1"),
        }, '<').to('.bar-2', { 
            y: -4,
            rotate: -45,
            duration: .75,
            ease: CustomEase.create("custom", "M0,0,C0.126,0.382,0.082,0.78,0.24,0.928,0.432,1.108,0.818,1.001,1,1"),
        }, '<').to(MainMenuItemsInner, { 
            autoAlpha: 1,
            y: 0,
            duration: .75,
            stagger: .075,
            ease: CustomEase.create("custom", "M0,0,C0.126,0.382,0.082,0.78,0.24,0.928,0.432,1.108,0.818,1.001,1,1"),
        }, '<');
        
    }   
}

async function fixedNav(thisContainer) {
    // Hide Navbar on on scroll down
    var navbar = thisContainer.querySelector('.navigation'),
        navbarHeight = navbar.offsetHeight,
        navTl = gsap.timeline({defaults: {duration: .5}, paused: true}),
        scrollNavTl = gsap.timeline({defaults: {duration: .5}, paused: true, ease: 'Power1.easeIn'}),
        prevScrollOffset = 0,
        isNavVisible = true;

    navTl.set(navbar, {backgroundColor: 'rgba(255, 255, 255, 0)'})
    navTl.to(navbar, {backgroundColor: 'rgba(255, 255, 255, 1)', duration: .2});
    scrollNavTl.to(navbar, {yPercent: -100});
    window.addEventListener('scroll', throttle(function (event) {
        let scrollOffset = document.documentElement.scrollTop || document.body.scrollTop;
        
        if (navbarHeight >= scrollOffset) {
            navbar.classList.add('nav-top');
            navbar.classList.remove('nav-fixed');
            navTl.reverse();
        } else {
            navbar.classList.remove('nav-top')
            navbar.classList.add('nav-fixed');
            navTl.play();
        }

        if (scrollOffset > prevScrollOffset && isNavVisible) {
            isNavVisible = false;
            scrollNavTl.play();
        } else if (scrollOffset < prevScrollOffset && !isNavVisible) {
            isNavVisible = true;
            scrollNavTl.reverse();
        }
        prevScrollOffset = scrollOffset;
    }, 300))
}

export {
    menu, fixedNav
}